export const DistributionApiEndpoints= {
  DELETE_DISTRIBUTION: {
    path: '/v1/distributions/:distributionUuid'
  },
  DELETE_EMAIL_ATTACHMENT: {
    path: '/v1/email-attachments/:attachmentUuid'
  },
  DELETE_MESSAGE_EMAIL: {
    path: '/v1/distribution-email-messages/:emailUuid'
  },
  DELETE_MESSAGE_SMS: {
    path: '/v1/distribution-sms-messages/:smsUuid'
  },
  GET_DEPARTMENT_UNIONS: {
    path: '/v1/department-unions'
  },
  GET_DISTRIBUTION: {
    path: '/v1/distributions/:distributionUuid'
  },
  GET_DISTRIBUTION_RECIPIENT_STATUSES: {
    path: '/v1/distributions/:distributionUuid/recipient-statuses'
  },
  GET_DISTRIBUTIONS_BY_USER_UUID: {
    path: '/v1/users/:userUuid/distributions'
  },
  GET_EMAIL_ATTACHMENT_CONSTRAINTS: {
    path: '/v1/email-attachments/constraints'
  },
  GET_INVOICE: {
    path: '/v1/invoices/:invoiceUuid'
  },
  GET_INVOICE_PDF_URL: {
    path: '/v1/invoice-vouchers/:invoiceUuid/pdf'
  },
  GET_INVOICE_VOUCHERS: {
    path: '/v1/invoice-vouchers'
  },
  GET_MESSAGE_PLACEHOLDERS: {
    path: '/v1/placeholders'
  },
  GET_RECIPIENT_LIST_BY_USER_UUID: {
    path: '/v1/users/:userUuid/recipient-lists'
  },
  GET_RECIPIENT_LIST_COUNTS: {
    path: '/v1/recipient-lists/:recipientListUuid/counts'
  },
  PATCH_DISTRIBUTION: {
    path: '/v1/distribution/:distributionUuid'
  },
  PATCH_MESSAGE_EMAIL: {
    path: '/v1/distribution-email-messages/:emailUuid'
  },
  PATCH_MESSAGE_SMS: {
    path: '/v1/distribution-sms-messages/:smsUuid'
  },
  POST_DISTRIBUTION: {
    path: '/v1/distributions'
  },
  POST_DISTRIBUTION_CANCEL: {
    path: '/v1/distributions/:distributionUuid/cancel'
  },
  POST_DISTRIBUTION_DISTRIBUTE: {
    path: '/v1/distributions/:distributionUuid/distribute'
  },
  POST_EMAIL_ATTACHMENT: {
    path: '/v1/email-attachments'
  },
  POST_MESSAGE_EMAIL: {
    path: '/v1/distribution-email-messages'
  },
  POST_MESSAGE_SMS: {
    path: '/v1/distribution-sms-messages'
  },
  PUT_DISTRIBUTION_RECIPIENT_LIST: {
    path: '/v1/distribution/:distributionUuid/recipient-list/:recipientListUuid'
  },
}
