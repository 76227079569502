import { environment } from '@environments/environment';
import { DistributionApiEndpoints } from '@config/apis/distribution/distribution-api-endpoints';
import { createApiConfiguration } from '@fagforbundet/ngx-components';

export const distributionApiConfig = createApiConfiguration<
  typeof DistributionApiEndpoints
>({
  baseUrl: environment.apis.distribution.baseUrl,
  endpoints: DistributionApiEndpoints,
});
